import { Flex, type ResponsiveValue, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useSettings } from '@/context/SettingsContext';
import { useAuth } from '@/context/AuthContext';
import ProBadge from '@/components/misc/ProBadge';
import { useCustomTheme } from '@/context/CustomThemeContext';

export default function LogoText({
  big = false,
  clickable = false,
  overrideColorScheme,
}: {
  big?: boolean;
  clickable?: boolean;
  overrideColorScheme?: string;
}) {
  const router = useRouter();
  const { colorScheme } = useSettings();
  const { isLoggedIn, isPro } = useAuth();
  const { logoFont } = useCustomTheme();

  const fontSize: ResponsiveValue<number> = big ? { base: 55, md: 90 } : { base: 28, sm: 32 };

  return (
    <Flex
      color={overrideColorScheme ? `${overrideColorScheme}.400` : !!colorScheme ? `${colorScheme}.400` : 'teal.400'}
      fontSize={fontSize}
      userSelect={'none'}
      cursor={clickable ? 'pointer' : 'default'}
      onClick={() => (clickable ? router.push('/') : null)}
      alignItems={'baseline'}
    >
      <Text mr={isLoggedIn && isPro ? 2 : 0} style={logoFont.style} letterSpacing={'-3px'}>
        {process.env.NEXT_PUBLIC_APP_NAME}
      </Text>
      {isLoggedIn && isPro && <ProBadge />}
    </Flex>
  );
}
