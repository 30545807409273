import { useAuth } from '@/context/AuthContext';
import { FormEvent, useState } from 'react';
import Image from 'next/image';
import logo from '@/public/logo.png';
import {
  AlertDescription,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Show,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FaEye, FaEyeSlash, FaGithub, FaPaypal } from 'react-icons/fa';
import { useCustomTheme } from '@/context/CustomThemeContext';
import LogoText from '@/components/structural/LogoText';
import { translate } from '@/context/TranslationContext';
import { Alert, AlertIcon } from '@chakra-ui/alert';

export default function Home() {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, signIn } = useAuth();
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });
  const t = translate('Home');

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    signIn(formState.email, formState.password).finally(() => setLoading(false));
  };

  if (isLoggedIn) {
    router.push('/app');
    return null;
  }

  return (
    <>
      <Container pt={20} maxWidth={'60ch'}>
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Show above={'sm'}>
            <Image height={150} src={logo} alt={'Pandomus logo'} />
          </Show>
          <Box ml={{ sm: 5 }} pl={{ sm: 5 }} borderLeft={{ sm: `2px solid teal.500` }}>
            <LogoText big />
          </Box>
        </Flex>

        <Alert mt={16} status={'warning'}>
          <AlertIcon />
          <AlertDescription>{t('loginAlert')}</AlertDescription>
        </Alert>

        <Box mt={16}>
          <form onSubmit={onSubmit}>
            <FormControl isRequired mb={4}>
              <FormLabel>{t('loginForm.email')}</FormLabel>
              <Input
                placeholder={t('loginForm.emailPlaceholder')}
                type={'email'}
                value={formState.email}
                onChange={(e) => setFormState((current) => ({ ...current, email: e.target.value }))}
              />
            </FormControl>
            <FormControl isRequired mb={4}>
              <FormLabel>{t('loginForm.password')}</FormLabel>

              <InputGroup>
                <Input
                  pr={12}
                  type={show ? 'text' : 'password'}
                  placeholder={t('loginForm.passwordPlaceholder')}
                  value={formState.password}
                  onChange={(e) => setFormState((current) => ({ ...current, password: e.target.value }))}
                />
                <InputRightElement width={12}>
                  <IconButton
                    h={'1.75rem'}
                    size={'sm'}
                    aria-label={'Toggle visibility'}
                    icon={show ? <FaEyeSlash /> : <FaEye />}
                    onClick={() => setShow((current) => !current)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Flex justifyContent={'end'}>
              <Button isLoading={loading} type={'submit'} loadingText={t('loginForm.loginInProgress')}>
                {t('loginForm.login')}
              </Button>
            </Flex>
          </form>
        </Box>
      </Container>
    </>
  );
}
